<div fxLayout="column" fxLayoutAlign="space-between stretch" class="main-div">
  <button class="button" mat-raised-button color="primary" *ngIf="currentUser.isAdmin" routerLink="/app-users">Admin</button>
  <button class="button" mat-raised-button color="primary" routerLink="/app-new-visit">New Visit</button>
  <h3>Recent Visits</h3>
  <mat-list class="visit-list">
    <mat-list-item  *ngFor="let visit of visits" (click)="visitClick(visit)">
        <fa-icon mat-list-icon [icon]="faUser"></fa-icon>
        <h4 mat-line>{{visit.firstName}} {{visit.surname}}</h4>
        <h5 mat-line>{{visit.telephone}}</h5>
      <!-- <mat-card class="card">
        <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <div>{{visit.firstName}} {{visit.surname}}</div>
          <div fxFlex></div>
        </mat-card-content>
      </mat-card> -->
    </mat-list-item>

  </mat-list>
</div>
