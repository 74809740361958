import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { Site } from '../_models'
import { SiteService } from '../_services'
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  deviceInfo = null;
  deviceType: string;
  siteInfo: Site;
  today = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  constructor(private deviceService: DeviceDetectorService,
    private router: Router,
    private siteService: SiteService
    ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    //console.log(this.deviceInfo);
    if (this.deviceService.isMobile())
      this.deviceType = "Mobile";
    else if(this.deviceService.isTablet())
      this.deviceType = "Tablet";
    else if(this.deviceService.isDesktop())
      this.deviceType = "Desktop";
    else
      this.deviceType = "Unknown";

      this.siteService.siteId$.subscribe(site => {
        if(site == null)
        {
          this.router.navigate(['/']);
          return;
        }

        this.siteInfo = site;
        //console.log(this.siteInfo);
      })
  }
  backClick() {
    this.router.navigate(['/'])
  }
  ngOnInit() {
  }

}
