import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Visit } from '../_models/visit';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VisitService {
  private visitor: Visit;

  private visitorSource = new BehaviorSubject(this.visitor);
  visitor$ = this.visitorSource.asObservable();

  public setVisitor(visitor: Visit){
    this.visitorSource.next(visitor);
  }

  constructor(private http: HttpClient) {
  }
  getAll(hostId: number) {
    return this.http.get<Visit[]>(`${environment.apiUrl}/VS200/getLastvisits/${hostId}`);
  }
  saveVisit(visit: Visit){
    return this.http.put<Visit>(`${environment.apiUrl}/VS200/savevisit`, visit);
  }
}
