export class User {
    _id: number;
    userName: string;
    password: string;
    firstNames: string;
    surname: string;
    isAdmin: boolean;
    token: string;
    siteId: number;
    isPassReset: boolean;
    isEnabled: boolean;
    address: string;
    contactNumber: string;
    email: string;
    nickname: string;
    idNumber: string;
    visitAG: number;
}
