import { Component, Input } from '@angular/core';

@Component({
    selector: 'input-read-only',
    template: `
<div class="divstyle">
    <div class="placeholder">{{placeholder}}</div>
    <div class="value">{{value}}</div>
</div>
  `,
    styles: ['.placeholder { font-weight: bold; font-size:x-small; color:Gray;  } .value {font-weight: bold; color:#4C4C4C; margin-left:5px;} .divstyle{margin:2px;}']
})
export class ReadOnlyInput {
    @Input() placeholder: string;
    @Input() value: string;
}
