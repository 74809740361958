import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { faSignOutAlt, faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';

import { AuthenticationService } from './_services';
import { User } from './_models';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentUser: User;
  showFooter = true;

  faSignOutAlt = faSignOutAlt;
  faGlobeAfrica = faGlobeAfrica;
  IsLoggedIn = false;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getWindowSize();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getWindowSize();

  }
  getWindowSize() {
    if (window.screen.height < 570) {
      this.showFooter = false;
    }
    else {
      this.showFooter = true;
    }
  }
  onNavigate(){
    //this.router.navigateByUrl("https://www.google.com");
    window.open("https://www.softconserv.com", "_blank");
}
}
