  <mat-toolbar class="toolbar" color="accent">
    <mat-toolbar-row>
      <button *ngIf="meFormGroup.valid" mat-icon-button fxFlex="20" (click)="backClick()">
        <mat-icon>chevron_left</mat-icon>
        <span>Cancel</span>
      </button>
      <div fxFlex></div>
      <button fxFlex="20" mat-button (click)="saveClick()">
        <mat-icon>save</mat-icon>
        <span class="back-edit-save-buttons_text">Save</span>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="main-div" fxLayout="row" fxLayoutAlign="space-between stretch">
    <div></div>
    <div fxFlex="90" >
      <form [formGroup]="meFormGroup" fxLayout="column" fxLayoutAlign="start stretch">
          <input-read-only *ngIf="currentUser.firstNames" placeholder="Name" value="{{currentUser.firstNames}}"></input-read-only>
          <mat-form-field *ngIf="!currentUser.firstNames">
            <input matInput placeholder="First Name" formControlName="firstName">
            <mat-error *ngIf="f.firstName.errors">{{getFirstNameError()}}</mat-error>
          </mat-form-field>
          <input-read-only *ngIf="currentUser.surname" placeholder="Surname" value="{{currentUser.surname}}"></input-read-only>
          <mat-form-field *ngIf="!currentUser.surname">
            <input matInput placeholder="Surname" formControlName="surname">
            <mat-error *ngIf="f.surname.errors">{{getSurnameError()}}</mat-error>
          </mat-form-field>
          <input-read-only *ngIf="currentUser.idNumber" placeholder="ID Number" value="{{currentUser.idNumber}}"></input-read-only>
          <mat-form-field *ngIf="!currentUser.idNumber">
            <input matInput placeholder="IDNumber" formControlName="idNumber">
            <mat-error *ngIf="f.idNumber.errors">{{getSurnameError()}}</mat-error>
          </mat-form-field>
          <input-read-only placeholder="Username" value="{{currentUser.userName}}"></input-read-only>
            <mat-form-field>
              <input matInput placeholder="Password" formControlName="password" [type]="!showPassword ? 'password' : 'text'">
              <fa-icon matSuffix [icon]="faEye" *ngIf="!showPassword" (click)="showHidePwd()"></fa-icon>
              <fa-icon matSuffix [icon]="faEyeSlash" *ngIf="showPassword" (click)="showHidePwd()"></fa-icon>
              <mat-hint>Password must have UPPERCASE, lowercase letters, numeric numbers, and must be longer than 6 characters</mat-hint>
              <mat-error *ngIf="f.password.errors">{{getPasswordError()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Confirm Password" formControlName="confirmPassword" [type]="!showPassword ? 'password' : 'text'">
              <mat-error *ngIf="f.confirmPassword.errors">{{getConfirmPasswordError()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Nick Name" formControlName="nickName">
                <fa-icon matSuffix [icon]="faUserAstronaut"></fa-icon>
              </mat-form-field>
            <mat-form-field>
              <input type="tel" matInput placeholder="Telephone" formControlName="telephone">
              <!-- <input type="tel" matInput placeholder="Telephone" formControlName="telephone" phoneMask [preValue]="meFormGroup.controls['telephone'].value"> -->
              <mat-icon matSuffix>phone</mat-icon>
              <mat-error *ngIf="f.telephone.errors">{{getTelephoneError()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Email" formControlName="email">
              <fa-icon matSuffix [icon]="faAt"></fa-icon>
              <mat-error *ngIf="f.email.errors">{{getEmailError()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Address" formControlName="address">
              <fa-icon matSuffix [icon]="faMapMarked"></fa-icon>
              <mat-error *ngIf="f.address.errors">{{getAddressError()}}</mat-error>
            </mat-form-field>
      </form>

    </div>
    <div></div>
  </div>


