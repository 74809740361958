export class Visit {
  id: number;
  siteId: number;
  hostId: number;
  firstName: string;
  surname: string;
  telephone: string;
  visitStart: string;
  visitEnd: string;
  otp: string;
  visitAG: number;
  email: string;
}
