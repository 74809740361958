<div *ngIf="isSpinnerShow" class="overlay-indicator">
  <mat-spinner></mat-spinner>
</div>
<div class="toolbar">
  <mat-toolbar color="accent">
    <mat-toolbar-row>
      <button mat-icon-button fxFlex="20" routerLink="/authland">
        <mat-icon>chevron_left</mat-icon>
        <span>Back</span>
      </button>
      <span fxFlex></span>
      <span>
        <mat-form-field class="search-options-formcontrols">
          <input class="search-options-formcontrols" matInput type="text" [(ngModel)]="searchValue" (keyup)="search($event)" />
          <mat-placeholder class="search-options-formcontrols">
            <mat-icon>search</mat-icon> <b> Search users</b>
          </mat-placeholder>
          <button mat-button class="search-options-formcontrols" *ngIf="searchValue" matSuffix mat-icon-button
            aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </span>
      <span fxFlex="10">
        <button mat-icon-button [matMenuTriggerFor]="sortMenu">
          <mat-icon>sort_by_alpha</mat-icon>
        </button>
      </span>
      <span fxFlex></span>
      <span fxFlex="10">
        <!--<button mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>-->
      </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-menu #sortMenu="matMenu">
    <button mat-menu-item [ngClass]="menuSortfNameClass" (click)="sortChanged('fName')">First Name</button>
    <button mat-menu-item [ngClass]="menuSortsNameClass" (click)="sortChanged('sName')">Surname</button>
    <button mat-menu-item [ngClass]="menuSortfNameDClass" (click)="sortChanged('fNameD')">First Name(Desc)</button>
    <button mat-menu-item [ngClass]="menuSortsNameDClass" (click)="sortChanged('sNameD')">Surname(Desc)</button>
    <button mat-menu-item [ngClass]="menuSortsDefaultClass" (click)="sortChanged('Default')">Default</button>
  </mat-menu>
  <!-- <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar> -->
</div>
<!-- <div class="alphabet-div" fxLayout="column" fxLayoutAlign="space-around">
  <div class="alphabet-div-items" [ngClass]="allClass" fxFlex="3" (click)="jumpClick('all')"> <mat-icon>all_inclusive</mat-icon> </div>
  <div class="alphabet-div-items" [ngClass]="aClass" fxFlex="3" (click)="jumpClick('A')"> A </div>
  <div class="alphabet-div-items" [ngClass]="bClass" fxFlex="3" (click)="jumpClick('B')"> B </div>
  <div class="alphabet-div-items" [ngClass]="cClass" fxFlex="3" (click)="jumpClick('C')"> C </div>
  <div class="alphabet-div-items" [ngClass]="dClass" fxFlex="3" (click)="jumpClick('D')"> D </div>
  <div class="alphabet-div-items" [ngClass]="eClass" fxFlex="3" (click)="jumpClick('E')"> E </div>
  <div class="alphabet-div-items" [ngClass]="fClass" fxFlex="3" (click)="jumpClick('F')"> F </div>
  <div class="alphabet-div-items" [ngClass]="gClass" fxFlex="3" (click)="jumpClick('G')"> G </div>
  <div class="alphabet-div-items" [ngClass]="hClass" fxFlex="3" (click)="jumpClick('H')"> H </div>
  <div class="alphabet-div-items" [ngClass]="iClass" fxFlex="3" (click)="jumpClick('I')"> I </div>
  <div class="alphabet-div-items" [ngClass]="jClass" fxFlex="3" (click)="jumpClick('J')"> J </div>
  <div class="alphabet-div-items" [ngClass]="kClass" fxFlex="3" (click)="jumpClick('K')"> K </div>
  <div class="alphabet-div-items" [ngClass]="lClass" fxFlex="3" (click)="jumpClick('L')"> L </div>
  <div class="alphabet-div-items" [ngClass]="mClass" fxFlex="3" (click)="jumpClick('M')"> M </div>
  <div class="alphabet-div-items" [ngClass]="nClass" fxFlex="3" (click)="jumpClick('N')"> N </div>
  <div class="alphabet-div-items" [ngClass]="oClass" fxFlex="3" (click)="jumpClick('O')"> O </div>
  <div class="alphabet-div-items" [ngClass]="pClass" fxFlex="3" (click)="jumpClick('P')"> P </div>
  <div class="alphabet-div-items" [ngClass]="qClass" fxFlex="3" (click)="jumpClick('Q')"> Q </div>
  <div class="alphabet-div-items" [ngClass]="rClass" fxFlex="3" (click)="jumpClick('R')"> R </div>
  <div class="alphabet-div-items" [ngClass]="sClass" fxFlex="3" (click)="jumpClick('S')"> S </div>
  <div class="alphabet-div-items" [ngClass]="tClass" fxFlex="3" (click)="jumpClick('T')"> T </div>
  <div class="alphabet-div-items" [ngClass]="uClass" fxFlex="3" (click)="jumpClick('U')"> U </div>
  <div class="alphabet-div-items" [ngClass]="vClass" fxFlex="3" (click)="jumpClick('V')"> V </div>
  <div class="alphabet-div-items" [ngClass]="wClass" fxFlex="3" (click)="jumpClick('W')"> W </div>
  <div class="alphabet-div-items" [ngClass]="xClass" fxFlex="3" (click)="jumpClick('X')"> X </div>
  <div class="alphabet-div-items" [ngClass]="yClass" fxFlex="3" (click)="jumpClick('Y')"> Y </div>
  <div class="alphabet-div-items" [ngClass]="zClass" fxFlex="3" (click)="jumpClick('Z')"> Z </div>
</div> -->
<div class="main-div" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex class="user-list">
        <mat-list>
            <mat-list-item *ngFor="let user of users" (click)="userClick(user)">
              <fa-icon mat-list-icon *ngIf="user.isEnabled" class="enabled-user" [icon]="faUser"></fa-icon>
              <fa-icon mat-list-icon *ngIf="!user.isEnabled" class="disabled-user" [icon]="faUserSlash"></fa-icon>
              <!-- <mat-icon *ngIf="user.isEnabled" mat-list-icon class="enabled-user">account_circle</mat-icon>
              <mat-icon *ngIf="!user.isEnabled" mat-list-icon class="disabled-user">account_circle</mat-icon> -->
              <h4 mat-line>{{user.surname}}, {{user.firstNames}}</h4>
              <h5 mat-line>{{user.userName}}</h5>
            </mat-list-item>
          </mat-list>
    </div>
    <div fxFlex="60" *ngIf="isLargeDevice" class="visitor-details-div">
      <app-editusers *ngIf="showDetails"></app-editusers>
    </div>
</div>
