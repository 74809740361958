import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { faAt, faEye, faEyeSlash, faMapMarked, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService, UserService } from '../_services';
import { User } from '../_models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material';

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.css']
})
export class MeComponent implements OnInit {

  meFormGroup: FormGroup;
  currentUser: User;

  faAt = faAt;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faMapMarked = faMapMarked;
  faUserAstronaut = faUserAstronaut;

  showPassword = false;

  message: string = 'Snack Bar opened.';
  setAutoHide: boolean = true;
  autoHide: number = 10000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  snackBarConfig = new MatSnackBarConfig();

  constructor(private router: Router,
    private _formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public snackBar: MatSnackBar,
    private userService: UserService ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      //console.log(this.currentUser);
      if(this.currentUser.isPassReset){
        this.snackBarConfig.duration = this.autoHide;
        this.snackBar.open("Please update your password!","", this.snackBarConfig);
      }
     }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup() {
    this.meFormGroup = this._formBuilder.group({
      id: [''],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      password: ['', [passwordValidator()]],
      confirmPassword: ['', [matchOtherValidator('password')]],
      telephone: ['', [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      address: ['', Validators.required],
      nickName:[''],
      idNumber: ['', Validators.required]
    })

    this.meFormGroup.patchValue({
      id: this.currentUser._id,
      firstName: this.currentUser.firstNames,
      surname: this.currentUser.surname,
      telephone: this.currentUser.contactNumber,
      address: this.currentUser.address,
      nickName: this.currentUser.nickname,
      email: this.currentUser.email,
      idNumber: this.currentUser.idNumber
    })
  }

  showHidePwd() {
    //console.log("show hide password");
    if (this.showPassword) {
      this.showPassword = false;
    }
    else {
      this.showPassword = true;
    }
  }

  backClick() {
    this.router.navigate(['/'])
  }

  saveClick() {
    // save validate form , save changes and navigate to home
    //console.log("save click!");
    //console.log(this.meFormGroup);
    if(this.meFormGroup.valid){

      var me = new User;
      me._id = this.meFormGroup.controls["id"].value;
      me.firstNames = this.meFormGroup.controls["firstName"].value;
      me.surname = this.meFormGroup.controls["surname"].value;
      me.password = this.meFormGroup.controls["password"].value;
      me.contactNumber = this.meFormGroup.controls["telephone"].value;
      me.email = this.meFormGroup.controls["email"].value;
      me.address = this.meFormGroup.controls["address"].value;
      me.nickname = this.meFormGroup.controls["nickName"].value;
      me.siteId = this.currentUser.siteId;
      me.userName = this.currentUser.userName;
      me.isAdmin = this.currentUser.isAdmin;
      me.isPassReset = false;
      me.idNumber = this.meFormGroup.controls["idNumber"].value;
      //console.log(me);
      this.userService.saveme(me).subscribe(results => {
        let localStorageJsonData = JSON.parse(localStorage.getItem('currentUser'));
        console.log(results);
        console.log(localStorageJsonData);
        localStorageJsonData.address = me.address;
        localStorageJsonData.cell = me.contactNumber;
        localStorageJsonData.email = me.email
        localStorageJsonData.firstname = me.firstNames;
        localStorageJsonData.id = me._id;
        localStorageJsonData.isAdmin = me.isAdmin;
        localStorageJsonData.isPasswordReset = me.isPassReset;
        localStorageJsonData.lastName = me.surname;
        localStorageJsonData.nickname = me.nickname;
        localStorage.setItem('currentUser', JSON.stringify(localStorageJsonData));

        this.router.navigate(['/'])
      })
    }
    else{
      this.validateForm();
    }

  }

  validateForm(){
    Object.keys(this.meFormGroup.controls).forEach(field => { // {1}
      const control = this.meFormGroup.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  keytab(event) {
    //console.log(event);
    //console.log(event.srcElement.value);

  }

  get f() { return this.meFormGroup.controls; }

  getFirstNameError() {
    return this.meFormGroup.controls['firstName'].hasError('required') ? 'First name is required' :
      '';
  }

  getSurnameError() {
    return this.meFormGroup.controls['surname'].hasError('required') ? 'Surname is required' :
      '';
  }

  getUsernameError() {
    return this.meFormGroup.controls['username'].hasError('required') ? 'Username is required' :
      this.meFormGroup.controls['username'].hasError('minlength') ? 'Username must be longer than 4 chars' :
        '';
  }

  getPasswordError() {
    return this.meFormGroup.controls['password'].hasError('required') ? 'Password cannot be blank!' :
      this.meFormGroup.controls['password'].hasError('validatePasswordLower') ? 'Password must contain Lowercase letters.' :
        this.meFormGroup.controls['password'].hasError('validatePasswordUpper') ? 'Password must contain Uppercase letters.' :
          this.meFormGroup.controls['password'].hasError('validatePasswordNum') ? 'Password must contain numeric digits.' :
            this.meFormGroup.controls['password'].hasError('validatePasswordSym') ? 'Password must contain symbol characters.' :
              this.meFormGroup.controls['password'].hasError('validatePasswordSym') ? 'Password must contain symbol characters.' :
                this.meFormGroup.controls['password'].hasError('validatePasswordLen') ? 'Password has a minimum of 6 characters.' :
                  '';
  }

  getConfirmPasswordError() {
    return this.meFormGroup.controls['confirmPassword'].hasError('required') ? 'Confirm Password is required' :
      this.meFormGroup.controls['confirmPassword'].hasError('matchOther') ? 'Password and confirm password must match' :
        '';
  }

  getTelephoneError() {
    return this.meFormGroup.controls['telephone'].hasError('required') ? 'Telephone is required' :
    this.meFormGroup.controls['telephone'].hasError('pattern') ? 'Invalid Telephone number' :
      '';
  }

  getEmailError() {
    return this.meFormGroup.controls['email'].hasError('required') ? 'Email is required' :
      this.meFormGroup.controls['email'].hasError('email') ? 'Not a valid email' :
        '';
  }

  getAddressError() {
    return this.meFormGroup.controls['address'].hasError('required') ? 'Address is required' :
      '';
  }
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {

    const value = control.value;
    let password = control.value;
    //console.log(password);
    if(password == null || password == "")
      return null;
    let hasLowerregexp = new RegExp('(.*[a-z].*)');
    if (!hasLowerregexp.test(password)) {
      //console.log('passwordHasLower');
      return { validatePasswordLower: { valid: false } }
    }

    let hasUpperRegexp = new RegExp('(.*[A-Z].*)');
    if (!hasUpperRegexp.test(password)) {
      //console.log('passwordHasUpper');
      return { validatePasswordUpper: { valid: false } }
    }

    let hasNumRegexp = new RegExp('(.*[0-9].*)');
    if (!hasNumRegexp.test(password)) {
      //console.log('passwordHasNumeric');
      return { validatePasswordNum: { valid: false } }
    }

    // let hasSymRegexp = new RegExp(/(?=.*[!@#\$%\^&\*])/);
    // if (!hasSymRegexp.test(password)) {
    //   console.log('passwordHasSymbol');
    //   console.log(password);
    //   return { validatePasswordSym: { valid: false } }
    // }

    if (password.length < 6) {
      //console.log('passwordMinChars');
      return { validatePasswordLen: { valid: false } }
    }

    //console.log('No Issues');
    return null;
  }
}

export function matchOtherValidator(otherControlName: string) {
  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {
    if (!control.parent) {
            return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true
      };
    }
    return null;
  }
}
