<mat-sidenav-container fullscreen class="mat-typography">
  <mat-sidenav mode="push" #sidenav>
    <mat-toolbar class="toolbar" color="accent">

      <img src="../assets/images/VS200Logo_Charcoal.svg" height="50" alt="VS200Logo"/>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/authland" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>home</mat-icon> Home
      </a>
      <a mat-list-item routerLink="/app-sites" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>business</mat-icon> Sites
      </a>
      <a mat-list-item routerLink="/app-features" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>shopping_basket</mat-icon> Features
      </a>
      <a mat-list-item routerLink="/app-installers" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>build</mat-icon> Installers
      </a>
      <a mat-list-item routerLink="/app-users" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>account_circle</mat-icon> Users
      </a>
      <a mat-list-item routerLink="/app-permissions" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>supervisor_account</mat-icon> Permissions
      </a>
      <a mat-list-item routerLink="/app-config" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>settings</mat-icon> System Config
      </a>
      <a mat-list-item routerLink="/app-accounts" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>account_balance_wallet</mat-icon> Pastel Import/Export
      </a>
      <a mat-list-item routerLink="/app-reports" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>assignment</mat-icon> Reports
      </a>
      <a mat-list-item routerLink="/app-fileuploadapk" routerLinkActive="active" (click)="sidenav.close()">
        <mat-icon>cloud_upload</mat-icon> Upload APK
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
    <button *ngIf="IsLoggedIn" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon class="md-24">menu</mat-icon>
    </button>
    <div></div>
    <img src="../assets/images/VS200Logo_White.svg" height="50" alt="VS200Logo"/>
    <div>
        <button *ngIf="!currentUser" mat-icon-button (click)="onNavigate()"
          matTooltip="www.softconserv.com" aria-label="Button softconserv.com">
            <fa-icon size="lg" style="color:grey" [icon]="faGlobeAfrica"></fa-icon>
          </button>
      <button *ngIf="currentUser" mat-icon-button [matMenuTriggerFor]="menu"
      matTooltip="Menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="currentUser" mat-menu-item routerLink="/app-me"
        matTooltip="About Me">
          <mat-icon>account_box</mat-icon>
          <span *ngIf="currentUser">{{currentUser.firstNames}}</span>
        </button>
        <button  *ngIf="currentUser" mat-menu-item routerLink="app-about"
        matTooltip="App About">
          <mat-icon>help</mat-icon>
          <span>About</span>
        </button>
        <button *ngIf="currentUser" mat-menu-item (click)="onNavigate()"
        matTooltip="www.softconserv.com">
            <fa-icon size="lg" style="color:grey" [icon]="faGlobeAfrica"></fa-icon>
            <span style="margin-left: 20px">Website</span>
          </button>
        <button *ngIf="currentUser" mat-menu-item (click)="logout()"
        matTooltip="Log Off">
          <fa-icon size="lg" style="color:grey" [icon]="faSignOutAlt"></fa-icon>
          <span style="margin-left: 20px">Logoff</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
  <div class="app-body">
    <router-outlet></router-outlet>
  </div>


<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
    <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/">Home</a>
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
    </div>
</nav>

<div class="jumbotron">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 offset-sm-3">
                <alert></alert>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div> -->
