<div fxLayout="row" fxLayoutAlign="space-between center">
  <div></div>
  <div>
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>account_circle</mat-icon>
        </div>
        <mat-card-title>Login</mat-card-title>
      </mat-card-header>
      <mat-card-content >
        <form [formGroup]="loginForm" fxLayout="column" fxLayoutAlign="space-between center" >
          <mat-form-field>
            <input matInput placeholder="Username" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <!-- <mat-error *ngIf="submitted && f.username.errors.required">Username is required</mat-error> -->
          </mat-form-field>
          <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <!-- <mat-error *ngIf="submitted && f.password.errors.required">Password is required</mat-error> -->
          </mat-form-field>

        </form>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center">
        <button aria-label="button login" mat-raised-button color="primary" [disabled]="loading" class="btn btn-primary" (click)="onSubmit($event)">Login</button>
        <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <!-- <button mat-stroked-button color="primary" routerLink="/register">Register</button> -->
      </mat-card-actions>
    </mat-card>
  </div>
  <div></div>
</div>
