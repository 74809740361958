<!-- <div class="user-details" *ngIf="!isEdit">
  <div class="toolbar">
    <mat-toolbar color="accent">
      <mat-toolbar-row>
        <button mat-icon-button fxFlex="20" (click)="backClick()">
          <mat-icon>chevron_left</mat-icon>
          <span>Close</span>
        </button>
        <div fxFlex></div>
        <button fxFlex="20" mat-button (click)="editClick()">
          <mat-icon>mode_edit</mat-icon>
          <span class="back-edit-save-buttons_text">Edit</span>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="form-div" fxLayout="column" fxLayoutAlign="space-around stretch">
      <input-read-only placeholder="Name" value="{{user.surname + , user.firstNames}}"></input-read-only>
      <input-read-only placeholder="Username" value="{{user.userName}}"></input-read-only>
      <input-read-only placeholder="Display name" value="user.nickname"></input-read-only>
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-slide-toggle [disabled]="true" [checked]="true">Enabled</mat-slide-toggle>
        <mat-slide-toggle [disabled]="true" [checked]="true">Administrator</mat-slide-toggle>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <input-read-only placeholder="Contact Number" value="0123487301"></input-read-only>
        <div fxFlex></div>
        <a href="{{'tel:' + contactNum}}" data-rel="external">
          <mat-icon aria-label="icon button phone" class="contact-icons">phone</mat-icon>
        </a>

        <a [href]="sanitize('sms:' + contactNum)" data-rel="external">
          <mat-icon aria-label="icon button text sms" class="contact-icons">textsms</mat-icon>
        </a>
        <a [href]="sanitize('https://api.whatsapp.com/send?phone=' + whatsAppNum)" class="contact-icons">
          <fa-icon size="2x" style="color:grey" [icon]="faWhatsapp"></fa-icon>
        </a>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <input-read-only placeholder="Email Address" value="michael@softconserv.com"></input-read-only>
        <a href="mailto:{{contactEmail}}">
          <mat-icon aria-label="icon button email" class="contact-icons">email</mat-icon>
        </a>
      </div>

      <input-read-only placeholder="Address" value="475 Kings Highway"></input-read-only>

    </div>
  </div>
</div> -->

<div class="user-details" >
  <div class="toolbar">
    <mat-toolbar color="accent">
      <mat-toolbar-row>
        <button mat-icon-button fxFlex="20" (click)="backClick()">
          <mat-icon>chevron_left</mat-icon>
          <span>Cancel</span>
        </button>
        <div fxFlex></div>
        <button fxFlex="20" mat-button (click)="saveClick()">
          <mat-icon>save</mat-icon>
          <span class="back-edit-save-buttons_text">Save</span>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <form [formGroup]="userFormGroup" class="form-div" fxLayout="column" fxLayoutAlign="space-around stretch">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field>
          <input matInput placeholder="User Name" formControlName="userName">
          <mat-error *ngIf="f.userName.errors">{{getUsernameError()}}</mat-error>
        </mat-form-field>
        <div>@</div>
        <mat-form-field>
          <input [disabled] matInput formControlName="sitePart">
        </mat-form-field>
      </div>

      <button fxFlex="20" mat-raised-button color="primary" (click)="getPassword()" [disabled]="user.password !== null">
          <fa-icon *ngIf="user.password == null" [icon]="faKey"></fa-icon>
          <fa-icon *ngIf="user.password !== null" [icon]="faCheck"></fa-icon>
          <span class="back-edit-save-buttons_text">Get Password</span>
        </button>
      <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstNames" (focus)="firstNamesfocus()">
        <mat-error *ngIf="f.firstNames.errors">{{getFirstNameError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Surname" formControlName="surname" (focus)="lastNamesfocus()">
        <mat-error *ngIf="f.surname.errors">{{getSurnameError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="ID Number" formControlName="iDNumber">
        <mat-error *ngIf="f.iDNumber.errors">{{getIDNumberError()}}</mat-error>
      </mat-form-field>
      <input-read-only placeholder="Display name" value="{{user.nickname}}"></input-read-only>
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-slide-toggle formControlName="isEnabled">{{this.userFormGroup.controls['isEnabled'].value ? "Active" : "In-Active"}}</mat-slide-toggle>
        <mat-slide-toggle formControlName="isAdmin">{{this.userFormGroup.controls['isAdmin'].value ? "Administrator" : "User"}}</mat-slide-toggle>
      </div>
      <mat-form-field>
        <input matInput placeholder="Address" formControlName="address">
        <mat-error *ngIf="f.address.errors">{{getAddressError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
        <mat-error *ngIf="f.email.errors">{{getEmailError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Cell" formControlName="contactNumber">
        <mat-error *ngIf="f.contactNumber.errors">{{getContactNumberError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="number" step="1" matInput placeholder="Visit Area Group" formControlName="visitAreaGroup">
        <mat-error *ngIf="f.visitAreaGroup.errors">{{getVisitAGError()}}</mat-error>
      </mat-form-field>
    </form>
  </div>
</div>
