import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, SiteService, AlertService } from '../_services';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faKey, faCheck } from '@fortawesome/free-solid-svg-icons';
import { User, Site } from '../_models'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material';
import { BottomAlert } from '../_components/bottom.alert';

@Component({
  selector: 'app-editusers',
  templateUrl: './editusers.component.html',
  styleUrls: ['./editusers.component.css']
})
export class EditusersComponent implements OnInit {

  isEdit = false;
  isThisChildComponent: boolean = false;
  faWhatsapp = faWhatsapp;
  faKey = faKey;
  faCheck = faCheck;
  userFormGroup: FormGroup;
  user: User;
  sitePart: string = "";
  siteInfo: Site;

  message: string = 'Snack Bar opened.';
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  IsLoggedIn = false;
  snackBarConfig = new MatSnackBarConfig();


  addExtraClass: boolean = false;

  constructor(private userService: UserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    private siteService: SiteService,
    public snackBar: MatSnackBar) {
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.horizontalPosition = 'center';
    this.snackBarConfig.duration = this.setAutoHide ? this.autoHide : 0;
    this.siteService.siteId$.subscribe(s => {
      this.siteInfo = s;
      //console.log(this.siteInfo);
    })
  }

  ngOnInit() {
    this.createFormGroup();
    this.userService.isChildComponent$.subscribe(childComp => {
      this.isThisChildComponent = childComp;
      this.isEdit = false;
    });
    this.userService.userId$.subscribe(user => {
      //console.log(user);
      if(user == null){
        this.router.navigate(['/'])
        return;
      }
      this.user = user;
      console.log(this.user);
      this.userFormGroup.patchValue({
        id: this.user._id,
        userName: this.user.userName != null ? this.user.userName.split("@")[0] : "",
        sitePart: this.user.userName != null ? this.user.userName.split("@")[1] : this.siteInfo.siteName.replace(" ", ""),
        firstNames: this.user.firstNames,
        surname: this.user.surname,
        address: this.user.address,
        contactNumber: this.user.contactNumber,
        email: this.user.email,
        nickname: this.user.nickname,
        iDNumber: this.user.idNumber,
        isAdmin: this.user.isAdmin,
        isEnabled: this.user.isEnabled,
        visitAreaGroup: this.user.visitAG
      })

    })
  }

  createFormGroup() {
    try{
      this.userFormGroup = this._formBuilder.group({
        id: [''],
        userName: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
        sitePart: [''],
        password: [''],
        firstNames: ['', Validators.required],
        surname: ['', Validators.required],
        isAdmin: [''],
        isEnabled: [''],
        address: [''],
        contactNumber: [''],
        email: ['', Validators.compose([Validators.required, Validators.email])],
        nickname: [''],
        iDNumber: [''],
        visitAreaGroup: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(10000)])]
      })
      this.userFormGroup.controls["sitePart"].disable();
    }
    catch(e){
      console.log(e);
      this.router.navigate(['/'])
        return;
    }

  }

  backClick() {
    if (!this.isEdit) {
      if (this.isThisChildComponent) {
        //console.log("Request close");
        this.userService.clickUserBackButton(true);
      } else {
        this.router.navigate(['/app-users'])
      }
    } else {
      this.isEdit = false;
    }
  }

  getPassword() {
    this.userService.getNewOTPassword().subscribe(OTP => {
      //console.log(OTP.toString());
      this.user.password = OTP.toString();
      this.user.isPassReset = true;
    })
  }

  saveClick() {
    if (this.userFormGroup.valid) {
      this.user.userName = this.userFormGroup.controls["userName"].value + "@" + this.userFormGroup.controls["sitePart"].value;
      this.user.firstNames = this.userFormGroup.controls["firstNames"].value;
      this.user.surname = this.userFormGroup.controls["surname"].value;
      this.user.isAdmin = this.userFormGroup.controls["isAdmin"].value;
      this.user.isEnabled = this.userFormGroup.controls["isEnabled"].value;
      this.user.address = this.userFormGroup.controls["address"].value;
      this.user.contactNumber = this.userFormGroup.controls["contactNumber"].value;
      this.user.email = this.userFormGroup.controls["email"].value;
      this.user.idNumber = this.userFormGroup.controls["iDNumber"].value;
      this.user.visitAG = this.userFormGroup.controls["visitAreaGroup"].value;
      console.log(this.user);
      this.userService.saveuser(this.user).subscribe(res => {
        //console.log("Success");
        //console.log(res);
        this.snackBarConfig.duration = this.autoHide;
        this.snackBar.open("Success", "", this.snackBarConfig);
        this.isEdit = false;
        if (this.isThisChildComponent) {
          this.isEdit = false;
        }
        else {
          this.router.navigate(['/app-users']);
        }
      },
        err => {
          console.log("Failed");
          console.log(err);
          this.snackBarConfig.duration = 15000;
          this.snackBar.open("Failed" + err, "", this.snackBarConfig);
        })
    }
    else {
      this.validateForm();
      this.snackBarConfig.duration = this.autoHide;
      this.snackBar.open("Form Validation Errors", "", this.snackBarConfig);
    }
    //this.isEdit = false;
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /* #region  Form validation Error */
  get f() { return this.userFormGroup.controls; }

  getFirstNameError() {
    return this.userFormGroup.controls['firstNames'].hasError('required') ? 'First name is required' :
      '';
  }

  getVisitAGError(){
    return this.userFormGroup.controls['visitAreaGroup'].hasError('required') ? 'Visit Area Group is required' :
    this.userFormGroup.controls['visitAreaGroup'].hasError('min') ? 'Visit Area Group must be greater than 0':
    this.userFormGroup.controls['visitAreaGroup'].hasError('max') ? 'Visit Area Group must be less than 10000':
    '';
  }

  getSurnameError() {
    return this.userFormGroup.controls['surname'].hasError('required') ? 'Surname is required' :
      '';
  }

  getUsernameError() {
    return this.userFormGroup.controls['userName'].hasError('required') ? 'Username is required' :
      this.userFormGroup.controls['userName'].hasError('minlength') ? 'Username must be longer than 4 chars' :
        '';
  }

  getIDNumberError() {
    return this.userFormGroup.controls['iDNumber'].hasError('required') ? 'ID number is required' :
      '';
  }

  getAddressError() {
    return this.userFormGroup.controls['address'].hasError('required') ? 'Address is required' :
      '';
  }

  getEmailError() {
    return this.userFormGroup.controls['email'].hasError('required') ? 'Email is required' :
      this.userFormGroup.controls['email'].hasError('email') ? 'Not a valid email' :
        '';
  }

  getContactNumberError() {
    return this.userFormGroup.controls['telephone'].hasError('required') ? 'Telephone is required' :
      this.userFormGroup.controls['telephone'].hasError('pattern') ? 'Invalid Telephone number' :
        '';
  }

  validateForm(){
    this.firstNamesfocus();
    this.lastNamesfocus();
    Object.keys(this.userFormGroup.controls).forEach(field => { // {1}
      const control = this.userFormGroup.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
  }
  /* #endregion */

  /* #region  Clear Default Values */
  firstNamesfocus() {
    //console.log("Firstname focus");
    let val = this.userFormGroup.controls["firstNames"].value
    if(val.includes("Name")){
      //console.log("Clear Value");
      this.userFormGroup.patchValue({firstNames: ""})
    }
  }
  lastNamesfocus() {
    //console.log("lastNames focus");
    let val = this.userFormGroup.controls["surname"].value
    if(val.includes("Surname")){
      //console.log("Clear Value");
      this.userFormGroup.patchValue({surname: ""})
    }
  }
  /* #endregion */
}
