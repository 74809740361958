import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import { first } from 'rxjs/operators';
import { faUser, faUserSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  isLargeDevice: boolean;
  isSpinnerShow: boolean = false;
  users: User[];
  currentUser: User;
  searchValue: any;
  timer;
  showDetails: boolean = false;

  faUser = faUser;
  faUserSlash = faUserSlash;

  sortValue: number = 5;
  menuSortfNameClass = 'sort-item';
  menuSortsNameClass = 'sort-item';
  menuSortfNameDClass = 'sort-item';
  menuSortsNameDClass = 'sort-item';
  menuSortsDefaultClass = 'sort-item-selected';

  constructor(private userService: UserService, private router: Router,
    private authenticationService: AuthenticationService) {
    this.getWindowSize();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.loadAllUsers();
  }

  private loadAllUsers() {
    this.isSpinnerShow = true;
    this.users = null
    this.userService.getAll(this.currentUser.siteId, this.searchValue, this.sortValue).pipe(first()).subscribe(users => {
      this.users = users;
      this.isSpinnerShow = false;
      //console.log(users);
      //console.log(this.users);
    });

  }

  sortChanged(event: any) {
    //console.log(event);
    switch (event) {
      case "fName":
        this.menuSortfNameClass = 'sort-item-selected';
        this.menuSortsNameClass = 'sort-item';
        this.menuSortfNameDClass = 'sort-item';
        this.menuSortsNameDClass = 'sort-item';
        this.menuSortsDefaultClass = 'sort-item';
        this.sortValue = 3;
        this.loadAllUsers();
        break;
      case "sName":
        this.menuSortfNameClass = 'sort-item';
        this.menuSortsNameClass = 'sort-item-selected';
        this.menuSortfNameDClass = 'sort-item';
        this.menuSortsNameDClass = 'sort-item';
        this.menuSortsDefaultClass = 'sort-item';
        this.sortValue = 1;
        this.loadAllUsers();
        break;
      case "fNameD":
        this.menuSortfNameClass = 'sort-item';
        this.menuSortsNameClass = 'sort-item';
        this.menuSortfNameDClass = 'sort-item-selected';
        this.menuSortsNameDClass = 'sort-item';
        this.menuSortsDefaultClass = 'sort-item';
        this.sortValue = 4;
        this.loadAllUsers();
        break;
      case "sNameD":
        this.menuSortfNameClass = 'sort-item';
        this.menuSortsNameClass = 'sort-item';
        this.menuSortfNameDClass = 'sort-item';
        this.menuSortsNameDClass = 'sort-item-selected';
        this.menuSortsDefaultClass = 'sort-item';
        this.sortValue = 2;
        this.loadAllUsers();
        break;
      case "Default":
        this.menuSortfNameClass = 'sort-item';
        this.menuSortsNameClass = 'sort-item';
        this.menuSortfNameDClass = 'sort-item';
        this. menuSortsNameDClass = 'sort-item';
        this.menuSortsDefaultClass = 'sort-item-selected';
        this.sortValue = 5;
        this.loadAllUsers();
        break;
    }
  }

  clearSearch() {
    this.searchValue = '';
    this.loadAllUsers();
  }
  search(event: any) {
    //console.log(event);
    //console.log(this.searchValue);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.loadAllUsers();
    }, 500);
  }

  userClick(user) {
    this.userService.setUser(user);
    if (!this.isLargeDevice) {
      this.router.navigate(['/app-editusers']);
      this.userService.setUserChildComoponent(false);
    }
    else {
      this.showDetails = true;
      this.userService.setUserChildComoponent(true);
      this.userService.isBackClick$.subscribe(click => {
        if (click) {
          //console.log("Request close recieved");
          this.showDetails = false;
        }

      })
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getWindowSize();
  }

  getWindowSize() {
    //clearTimeout(this.timer);
    //this.timer = setTimeout(() => {

    //}, 500);
    let w: number = window.screen.width;
    let h: number = window.screen.height;
    let o = w > h ? 'l' : 'p';
    let s;
    //console.log(w + '/' + h);
    //console.log(o);
    if ((w <= 500 && o == 'p') || (w <= 850 && o == 'l')) { this.isLargeDevice = false; }
    else { this.isLargeDevice = true; }
    //console.log(this.isLargeDevice);
  }

}
