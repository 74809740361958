import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { User, QueryParams } from '../_models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {

    private user: User;
    private isBackClick: boolean = false;
    private isChildComponent: boolean;

    private userIdSource = new BehaviorSubject(this.user);
    userId$ = this.userIdSource.asObservable();

    private userBackClick = new BehaviorSubject(this.isBackClick);
    isBackClick$ = this.userBackClick.asObservable();

    private userChildComponent = new BehaviorSubject(this.isChildComponent);
    isChildComponent$ = this.userChildComponent.asObservable();

    constructor(private http: HttpClient) {
    }

    public setUser(user: User) {
        this.userIdSource.next(user);
        this.userBackClick.next(false);
    }

    public clickUserBackButton(backClick: boolean){
        this.userBackClick.next(backClick);
    }

    public setUserChildComoponent(isChild: boolean){
        this.userChildComponent.next(isChild);
    }

    getAll(siteId: number, searchValue: string, sortOrder: number) {
        return this.http.get<User[]>(`${environment.apiUrl}/VS200/getsitehosts?siteId=${siteId}&searchValue=${searchValue}&sortOrder=${sortOrder}`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/VS200/${id}`);
    }

    getNewOTPassword(){
      return this.http.get(`${environment.apiUrl}/VS200/getNewOTPassword`)
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/VS200/register`, user);
    }

    saveme(user: User) {
        return this.http.put(`${environment.apiUrl}/VS200/saveme`, user);
    }

    saveuser(user: User){
      return this.http.put(`${environment.apiUrl}/VS200/saveuser`, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`);
    }
}
