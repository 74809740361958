import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from "@angular/router";

import { User, Visit } from '../_models';
import { SiteService, AuthenticationService, VisitService } from '../_services';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({ templateUrl: 'home.component.html',
styleUrls: ['./home.component.css'] })
export class HomeComponent implements OnInit, OnDestroy {
    currentUser: User;
    currentUserSubscription: Subscription;
    users: User[] = [];
    visits: Visit[] = [];
    faUser = faUser;
    isAdminUser = true;

    constructor(
        private authenticationService: AuthenticationService,
        private visitService: VisitService,
        private siteService: SiteService,
        private router: Router
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
        this.siteService.getSite(this.currentUser.siteId).subscribe(s => {
          this.siteService.setSite(s);
        });
        this.visitService.setVisitor(null);
    }

    ngOnInit() {
        this.loadVisits();
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.currentUserSubscription.unsubscribe();
    }

    private loadVisits(){
      this.visitService.getAll(this.currentUser._id).pipe(first()).subscribe(visits => {

        this.visits = visits;
        console.log(this.visits);
      },
      err => {
        console.log(err);
      })
    }

    visitClick(visit){
      //console.log(visit);
      this.visitService.setVisitor(visit);
      this.router.navigate(['/app-new-visit']);
    }
}
