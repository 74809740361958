import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Site } from '../_models';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SiteService {
  site: Site;

  private siteSource = new BehaviorSubject(this.site);
  siteId$ = this.siteSource.asObservable();

  public setSiteName(site: Site) {
    this.siteSource.next(site);
  }

  constructor(private http: HttpClient) { }

  public setSite(site: Site) {
    this.siteSource.next(site);
  }

  getSite(siteId: number){
    return this.http.get<Site>(`${environment.apiUrl}/VS200/getsiteinfo/${siteId}`)
  }
}
