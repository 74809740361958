import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReadOnlyInput } from './_components/read.only.input.component';
import { DeviceDetectorModule } from 'ngx-device-detector';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppMaterialModule } from './material.modules';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BottomAlert } from './_components/bottom.alert';
import { AlertService } from './_services/index';
import { JwtInterceptor, ErrorInterceptor } from './_helpers/index';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UsersComponent } from './users/users.component';
import { EditusersComponent } from './editusers/editusers.component';
import { NewVisitComponent } from './new-visit/new-visit.component';
import { MeComponent } from './me/me.component';
import { AboutComponent } from './about/about.component';
import { TelInput } from './_components/tel-input'
import { PhoneMaskDirective } from './_components/phone-mask.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    BottomAlert,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    UsersComponent,
    EditusersComponent,
    NewVisitComponent,
    MeComponent,
    AboutComponent,
    ReadOnlyInput,
    TelInput,
    PhoneMaskDirective
  ],
  imports: [
    AppMaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AlertService
    // provider used to create fake backend
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
