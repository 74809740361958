import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './_guards';
import { UsersComponent } from './users/users.component';
import { EditusersComponent } from './editusers/editusers.component';
import { NewVisitComponent } from './new-visit/new-visit.component';
import { MeComponent } from './me/me.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'app-users', component: UsersComponent, canActivate: [AuthGuard] },
    { path: 'app-editusers', component: EditusersComponent, canActivate: [AuthGuard] },
    { path: 'app-new-visit', component: NewVisitComponent, canActivate: [AuthGuard] },
    { path: 'app-me', component: MeComponent, canActivate: [AuthGuard] },
    { path: 'app-about', component: AboutComponent, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
