<mat-toolbar class="toolbar" color="accent">
  <mat-toolbar-row>
    <button mat-icon-button fxFlex="20" (click)="backClick()">
      <mat-icon>chevron_left</mat-icon>
      <span>Cancel</span>
    </button>
    <div fxFlex></div>
    <div>New Visit</div>
    <div fxFlex></div>
    <button fxFlex="20" mat-button (click)="doneClick()" *ngIf="isOTP">
      <mat-icon>done</mat-icon>
      <span class="back-edit-save-buttons_text">Done</span>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="main-div" fxLayout="row" fxLayoutAlign="space-between stretch">
  <div></div>
  <div fxFlex="90">
    <form [formGroup]="visitFormgroup" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field>
        <input matInput placeholder="Name" formControlName="firstName">
        <mat-error *ngIf="f.firstName.errors">{{getFirstNameError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Surname" formControlName="surname">
        <mat-error *ngIf="f.surname.errors">{{getSurnameError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Mobile Number" formControlName="cell">
        <mat-error *ngIf="f.cell.errors">{{getMobileError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Email Address" formControlName="email">
        <mat-error *ngIf="f.email.errors">{{getEmailError()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="date" min={{minDTStart}} placeholder="Visit Start Date" (change)="startDateSelect($event)" formControlName="startDt">
        <mat-error *ngIf="f.startDt.errors">{{getStartDtError()}}</mat-error>

      </mat-form-field>
      <mat-form-field>
        <input matInput type="date" min="{{minDTEnd}}" placeholder="Visit End Date" (change)="endDateSelect($event)" formControlName="endDt">
        <mat-error *ngIf="f.endDt.errors">{{getEndDtError()}}</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="getOTP()" *ngIf="!isOTP" [disabled]="btnGetOPTDisabled">Get OTP</button>
    </form>
    <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="isOTP">
      <input-read-only placeholder="One Time Password" value="{{OTP}}"></input-read-only>
      <div fxFlex>
        <a *ngIf="isAndroid" [href]="sanitize('sms://' + otpTelephone + '?body=' + message)" data-rel="external">
          <button mat-raised-button color="primary" routerLink="/">
            <fa-icon [icon]="faCommentAlt"></fa-icon>
          </button>
        </a>
        <a *ngIf="isIOS" [href]="sanitize('sms://' + otpTelephone + '&body=' + message)" data-rel="external">
          <button mat-raised-button color="primary" routerLink="/">
            <fa-icon [icon]="faCommentAlt"></fa-icon>
          </button>
        </a>
        <a *ngIf="isDesktop" [href]="sanitize('sms://' + otpTelephone + '?body=' + message)" data-rel="external">
          <button  mat-raised-button color="primary" routerLink="/">
            <fa-icon [icon]="faCommentAlt"></fa-icon>
          </button>
        </a>
        <a [href]="sanitize('https://wa.me/' + whatsAppotpTelephone + '/?text=' + message)">
          <button  mat-raised-button color="primary" routerLink="/">
            <fa-icon [icon]="faWhatsapp"></fa-icon>
          </button>
        </a>
        <a *ngIf="otpEmail != ''" [href]="sanitize('mailto:' + otpEmail + '?subject=Visit OTP&body=' + message)">
          <button mat-raised-button color="primary"  routerLink="/">
            <fa-icon [icon]="faEnvelope"></fa-icon>
          </button>
        </a>
      </div>

    </div>
  </div>
  <div></div>

</div>
