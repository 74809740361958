import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VisitService, SiteService, AuthenticationService } from '../_services';
import { Visit, Site, User } from '../_models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-new-visit',
  templateUrl: './new-visit.component.html',
  styleUrls: ['./new-visit.component.css']
})
export class NewVisitComponent implements OnInit {

  visitFormgroup: FormGroup;
  faWhatsapp = faWhatsapp;
  faCommentAlt = faCommentAlt;
  faEnvelope = faEnvelope;
  isOTP = false;
  OTP = "";
  OtpShared = false;
  visitor: Visit
  deviceInfo = null;
  isIOS = false;
  isAndroid = false;
  isDesktop = false;

  btnGetOPTDisabled = false;

  siteInfo: Site;
  currentUser: User;

  //message: string = 'Snack Bar opened.';
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  snackBarConfig = new MatSnackBarConfig();

  today = new Date();
  jstoday = '';

  otpTelephone = '';
  whatsAppotpTelephone = '';
  otpEmail = '';
  message = '';

  constructor(private _formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private visitorService: VisitService,
    private siteService: SiteService,
    public snackBar: MatSnackBar,
    private authenticationService: AuthenticationService

  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getDeviceInfo();
    this.siteService.siteId$.subscribe(s => {
      this.siteInfo = s;
      //console.log(this.siteInfo);
    })
  }

  ngOnInit() {
    this.createFormGroup();
    this.visitorService.visitor$.subscribe(v => {
      this.visitor = v;
      //console.log(this.visitor);
      if (v)
        this.visitFormgroup.patchValue({
          firstName: this.visitor.firstName,
          surname: this.visitor.surname,
          cell: this.visitor.telephone,
          email: this.visitor.email
        }

        )
    })
  }

  createFormGroup() {
    this.visitFormgroup = this._formBuilder.group({
      id: [''],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      cell: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      startDt: ['', [Validators.required]],
      endDt: ['', [Validators.required]]
    })
    this.visitFormgroup.patchValue({
      startDt: formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530'),
      endDt: formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530'),
    })
    if (this.isDesktop) {
      this.visitFormgroup.addControl('email', new FormControl('', Validators.compose([Validators.required, Validators.email])));
    }
    else{
      this.visitFormgroup.addControl('email', new FormControl('', Validators.compose([Validators.email])));
    }
  }

  minDTEnd = this.getToday();
  minDTStart = this.getToday();
  startDateSelect(event) {
    //console.log(event);
    this.visitFormgroup.patchValue({
      endDt: this.visitFormgroup.controls['startDt'].value
    });
    this.minDTEnd = this.visitFormgroup.controls['startDt'].value;
  }

  getToday(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }

  endDateSelect($event){
    this.validateFG();
  }

  getOTP() {
    if (this.visitFormgroup.valid) {
      this.btnGetOPTDisabled = true;
      var visit = new Visit;
      visit.firstName = this.visitFormgroup.controls['firstName'].value;
      visit.siteId = this.siteInfo.siteId;
      visit.surname = this.visitFormgroup.controls['surname'].value;
      visit.telephone = this.visitFormgroup.controls['cell'].value;
      visit.visitStart = this.visitFormgroup.controls['startDt'].value;
      visit.visitEnd = this.visitFormgroup.controls['endDt'].value;
      visit.siteId = this.siteInfo.siteId;
      visit.hostId = this.currentUser._id;
      visit.visitAG = this.currentUser.visitAG;
      visit.email = this.visitFormgroup.controls['email'].value;
      console.log(visit);
      this.visitorService.saveVisit(visit).subscribe(results => {
        //console.log(results);
        this.snackBarConfig.duration = this.autoHide;
        this.snackBar.open("Success", "", this.snackBarConfig);
        this.isOTP = true;
        var visitSaved = new Visit;
        visitSaved = results;
        //console.log(visitSaved);
        this.OTP = visitSaved.otp;
        this.otpTelephone = visitSaved.telephone;
        this.otpEmail = visitSaved.email;
        this.whatsAppotpTelephone = '+27' + visitSaved.telephone;
        this.message = 'Hi ' + visitSaved.firstName +
          '. You have been registered to visit ' +
          this.currentUser.firstNames +
          ' on ' + formatDate(visitSaved.visitStart, 'yyyy-MM-dd', 'en-US', '+0530') +
          '. Your One-Time pin for entry is: ' + this.OTP + '. Please adhere to the rules of the complex. Regards.'
      },
        err => {
          console.log(err);
          this.snackBarConfig.duration = 0;
          this.snackBar.open(err, "", this.snackBarConfig);
        });

    }
    else {
      this.validateFG();
    }


  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    switch (this.deviceInfo.device) {
      case "android":
        this.isAndroid = true;
        this.isIOS = false;
        this.isDesktop = false;
        break;
      case "iphone":
        this.isAndroid = false;
        this.isIOS = true;
        this.isDesktop = false;
        break;
      default:
        this.isAndroid = false;
        this.isIOS = false;
        this.isDesktop = true;
    }
  }

  doneClick() {
    this.router.navigate(['/'])
  }

  backClick() {
    this.visitorService.setVisitor(null);
    this.router.navigate(['/'])
  }

  get f() { return this.visitFormgroup.controls; }

  error:any={isError:false,errorMessage:''};

  validateFG() {
    this.getFirstNameError();
    this.getSurnameError();
    this.getMobileError();
    this.getStartDtError();
    this.getEndDtError();
  }

  getFirstNameError() {
    return this.visitFormgroup.controls['firstName'].hasError('required') ? 'First name is required' :
      '';
  }

  getSurnameError() {
    return this.visitFormgroup.controls['surname'].hasError('required') ? 'Surname is required' :
      '';
  }

  getMobileError() {
    return this.visitFormgroup.controls['startDt'].hasError('required') ? 'Mobile number is required' :
      '';
  }
  getStartDtError() {
    return this.visitFormgroup.controls['startDt'].hasError('required') ? 'Start Date is required' :
      '';
  }
  getEndDtError() {
    return this.visitFormgroup.controls['endDt'].hasError('required') ? 'Visit End Date is required' :
      '';
  }

  getEmailError() {
    return this.visitFormgroup.controls['email'].hasError('required') ? 'Email is a required field' :
      this.visitFormgroup.controls['email'].hasError('email') ? 'Valid email address required' :
        '';
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



}

